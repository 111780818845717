<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="公司/项目" prop="monitorpointname" style="width: 25%">
              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype!='0'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="userInfo.usertype!='0'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="特别事件报告编号" prop="special_event_num" style="width: 25%">
              <a-input v-model="queryParams.special_event_num"></a-input>
            </a-form-model-item>
            <a-form-model-item label="发生时段" prop="queryInstallDate" style="width: 25%">
              <a-range-picker v-model="queryInstallDate" :default-value="[moment(`${queryParams.starttime.substring(0,10)}`, 'YYYY-MM-DD'), moment(`${queryParams.endtime.substring(0,10)}`, 'YYYY-MM-DD')]"></a-range-picker>
            </a-form-model-item>
            <div class="query-btns" style="float: right;width: 20%">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
            </div>
            <div v-show="showAdvanced" class="advanced-query">
              <a-form-model-item label="特别事件类别" prop="special_event_type" style="width: 25%">
                <a-select v-model="queryParams.special_event_type">
                  <a-select-option value="治安">治安</a-select-option>
                  <a-select-option value="消防">消防</a-select-option>
                  <a-select-option value="意外">意外</a-select-option>
                  <a-select-option value="跑水">跑水</a-select-option>
                  <a-select-option value="能源供应中断">能源供应中断</a-select-option>
                  <a-select-option value="设施设备故障">设施设备故障</a-select-option>
                  <a-select-option value="卫生">卫生</a-select-option>
                  <a-select-option value="信息安全">信息安全</a-select-option>
                  <a-select-option value="其它">其它</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="特别事件子类别" prop="special_child_type" style="width: 25%">
                <a-select v-model="queryParams.special_child_type">
                  <a-select-option v-if="queryParams.special_event_type==='治安'" value="群访">群访</a-select-option>
                  <a-select-option v-if="queryParams.special_event_type==='治安'" value="扰乱秩序">扰乱秩序</a-select-option>
                  <a-select-option v-if="queryParams.special_event_type==='治安'" value="纠纷">纠纷</a-select-option>
                  <a-select-option v-if="queryParams.special_event_type==='治安'" value="斗殴">斗殴</a-select-option>
                  <a-select-option v-if="queryParams.special_event_type==='治安'" value="盗抢">盗抢</a-select-option>
                  <a-select-option v-if="queryParams.special_event_type==='治安'" value="其它">其它</a-select-option>
                  <a-select-option v-if="queryParams.special_event_type==='消防'" value="火情">火情</a-select-option>
                  <a-select-option v-if="queryParams.special_event_type==='消防'" value="电梯困人">电梯困人</a-select-option>
                  <a-select-option v-if="queryParams.special_event_type==='意外'" value="高空坠落">高空坠落</a-select-option>
                  <a-select-option v-if="queryParams.special_event_type==='意外'" value="倒塌/塌陷">倒塌/塌陷</a-select-option>
                  <a-select-option v-if="queryParams.special_event_type==='意外'" value="人员意外受伤">人员意外受伤</a-select-option>
                  <a-select-option v-if="queryParams.special_event_type==='意外'" value="触电">触电</a-select-option>
                  <a-select-option v-if="queryParams.special_event_type==='意外'" value="突发疾病">突发疾病</a-select-option>
                  <a-select-option v-if="queryParams.special_event_type==='意外'" value="车辆/交通">车辆/交通</a-select-option>
                  <a-select-option v-if="queryParams.special_event_type==='意外'" value="水浸">水浸</a-select-option>
                  <a-select-option v-if="queryParams.special_event_type==='意外'" value="爆炸">爆炸</a-select-option>
                  <a-select-option v-if="queryParams.special_event_type==='意外'" value="其它">其它</a-select-option>
                  <a-select-option v-if="queryParams.special_event_type==='跑水'" value="消防水">消防水</a-select-option>
                  <a-select-option v-if="queryParams.special_event_type==='跑水'" value="给/排水">给/排水</a-select-option>
                  <a-select-option v-if="queryParams.special_event_type==='跑水'" value="暖通水">暖通水</a-select-option>
                  <a-select-option v-if="queryParams.special_event_type==='跑水'" value="生活热水">生活热水</a-select-option>
                  <a-select-option v-if="queryParams.special_event_type==='跑水'" value="其它">其它</a-select-option>
                  <a-select-option v-if="queryParams.special_event_type==='能源供应中断'" value="停电">停电</a-select-option>
                  <a-select-option v-if="queryParams.special_event_type==='能源供应中断'" value="发电">发电</a-select-option>
                  <a-select-option v-if="queryParams.special_event_type==='能源供应中断'" value="停水">停水</a-select-option>
                  <a-select-option v-if="queryParams.special_event_type==='能源供应中断'" value="停燃气">停燃气</a-select-option>
                  <a-select-option v-if="queryParams.special_event_type==='能源供应中断'" value="其它">其它</a-select-option>
                  <a-select-option v-if="queryParams.special_event_type==='设施设备故障'" value="停用电梯">停用电梯</a-select-option>
                  <a-select-option v-if="queryParams.special_event_type==='设施设备故障'" value="停用空调">停用空调</a-select-option>
                  <a-select-option v-if="queryParams.special_event_type==='设施设备故障'" value="其它">其它</a-select-option>
                  <a-select-option v-if="queryParams.special_event_type==='卫生'" value="重大传染病">重大传染病</a-select-option>
                  <a-select-option v-if="queryParams.special_event_type==='卫生'" value="重大不明原因疾病">重大不明原因疾病</a-select-option>
                  <a-select-option v-if="queryParams.special_event_type==='卫生'" value="中毒">中毒</a-select-option>
                  <a-select-option v-if="queryParams.special_event_type==='卫生'" value="其它">其它</a-select-option>
                  <a-select-option v-if="queryParams.special_event_type==='信息安全'" value="业户信息">业户信息</a-select-option>
                  <a-select-option v-if="queryParams.special_event_type==='信息安全'" value="信息化系统">信息化系统</a-select-option>
                  <a-select-option v-if="queryParams.special_event_type==='信息安全'" value="其它">其它</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="生产安全事故等级" prop="accident_level" style="width: 25%">
                <a-select v-model="queryParams.accident_level">
                  <a-select-option value="无">无</a-select-option>
                  <a-select-option value="一般事故">一般事故</a-select-option>
                  <a-select-option value="较大事故">较大事故</a-select-option>
                  <a-select-option value="重大事故">重大事故</a-select-option>
                  <a-select-option value="特别重大事故">特别重大事故</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="原因类别" prop="cause_category" style="width: 25%">
                <a-select v-model="queryParams.cause_category">
                  <a-select-option value="业户">业户</a-select-option>
                  <a-select-option value="外来人员">外来人员</a-select-option>
                  <a-select-option value="外委人员">外委人员</a-select-option>
                  <a-select-option value="员工">员工</a-select-option>
                  <a-select-option value="能源供应单位">能源供应单位</a-select-option>
                  <a-select-option value="设施设备">设施设备</a-select-option>
                  <a-select-option value="台风">台风</a-select-option>
                  <a-select-option value="暴雨">暴雨</a-select-option>
                  <a-select-option value="地震">地震</a-select-option>
                  <a-select-option value="雷电">雷电</a-select-option>
                  <a-select-option value="其它">其它</a-select-option>
                </a-select>
              </a-form-model-item>
            </div>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">{{pageTitle}}</h3>
          <div class="table-btns">
            <a-button v-if="btnList.includes('导出')" @click="exportModal()" style="margin-right: 10px"><a-icon type="export"></a-icon>批量导出</a-button>
            <a-button v-if="btnList.includes('新增')" @click="addRecord()" style="margin-right: 10px" type="primary"><a-icon type="plus"></a-icon>新增</a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData()"></a-icon>
          </div>
        </div>
        <a-table :customRow="customRow" :columns="tableColumns" :data-source="tableData" row-key="special_event_id" :scroll="{x:3000}" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="start_time" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="report_time" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="receive_time" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="arrive_time" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.special_event_id">详情</a-menu-item>
                <a-menu-item v-if="btnList.includes('修改')||userInfo.usertype==0" :key="'edit-'+record.special_event_id">修改</a-menu-item>
                <a-menu-item v-if="btnList.includes('删除')||userInfo.usertype==0" :key="'delete-'+record.special_event_id">删除</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <add-or-edit-modal :visible.sync="modalVisible" :show-type="modalShowType" :detailData="modalDetailData" @get-operation-result="getTableData"></add-or-edit-modal>
  </page-container>
</template>
<script>
import moment from "moment";
import {mapGetters, mapState} from "vuex";
import pagination from "@/mixins/pagination";
import {getItemFromArrayByKey, getTimeRange} from "U/index";
import deptselect from "@/mixins/deptselect";
import addOrEditModal from "V/businessManagement/reportForms/specialEvent/addOrEditModal";
import {
  deleteSpecialEvent, exportSpecialEventListExcel,
  getSpecialEventListByCondition
} from "A/businessmanagement";
export default {
  name: "SpecialEvent",
  mixins: [deptselect,pagination],
  components: {
    addOrEditModal,
  },
  data() {
    return {
      moment,
      tableData: [],
      tableLoading: false,
      showAdvanced: false,
      queryParams:{
        monitorpointnum:'',
        monitorpointname:'',
        special_event_num:'',
        accident_level:'',
        special_event_type:'',
        special_child_type:'',
        starttime:'',
        endtime:'',
        userdepsid:'',
      },
      tableColumns:[
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        {
          title: '特别事件报告编号',
          dataIndex: 'special_event_num',
          key: 'special_event_num',
          ellipsis: true,
        },
        {
          title: '生产安全事故等级',
          dataIndex: 'accident_level',
          key: 'accident_level',
          ellipsis: true,
        },
        {
          title: '特别事件类别',
          dataIndex: 'special_event_type',
          key: 'special_event_type',
          ellipsis: true,
        },
        {
          title: '特别事件子类别',
          dataIndex: 'special_child_type',
          key: 'special_child_type',
          ellipsis: true,
        },
        {
          title: '发生时间',
          dataIndex: 'start_time',
          key: 'start_time',
          ellipsis: true,
          scopedSlots: { customRender: 'start_time' },
        },
        {
          title: '报告时间',
          dataIndex: 'report_time',
          key: 'report_time',
          ellipsis: true,
          scopedSlots: { customRender: 'report_time' },
        },
        {
          title: '接报时间',
          dataIndex: 'receive_time',
          key: 'receive_time',
          ellipsis: true,
          scopedSlots: { customRender: 'receive_time' },
        },
        {
          title: '到场时间',
          dataIndex: 'arrive_time',
          key: 'arrive_time',
          ellipsis: true,
          scopedSlots: { customRender: 'arrive_time' },
        },
        {
          title: '事发地点',
          dataIndex: 'incident_scene',
          key: 'incident_scene',
          ellipsis: true,
        },
        {
          title: '伤/亡人数',
          dataIndex: 'casualty',
          key: 'casualty',
          ellipsis: true,
        },
        {
          title: '报告人',
          dataIndex: 'reporter',
          key: 'reporter',
          ellipsis: true,
        },
        {
          title: '到场人员',
          dataIndex: 'arrive_man',
          key: 'arrive_man',
          ellipsis: true,
        },
        {
          title: '事情经过',
          dataIndex: 'whole_story',
          key: 'whole_story',
          ellipsis: true,
        },
        {
          title: '采取的行动',
          dataIndex: 'take_steps',
          key: 'take_steps',
          ellipsis: true,
        },
        {
          title: '直接经济损失',
          dataIndex: 'damage',
          key: 'damage',
          ellipsis: true,
        },
        {
          title: '预防措施',
          dataIndex: 'prevent_measure',
          key: 'prevent_measure',
          ellipsis: true,
        },
        {
          title: '原因类别',
          dataIndex: 'cause_category',
          key: 'cause_category',
          ellipsis: true,
        },
        {
          title: '结论',
          dataIndex: 'conclusion',
          key: 'conclusion',
          ellipsis: true,
        },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          ellipsis: true,
          align:'center',
          scopedSlots: { customRender: 'operation' },
          width: 70
        },
      ],
      queryInstallDate:null,
      userdepidCascaderSelected: [],
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,

      //操作按钮权限
      btnList:[],
      menu:{},
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    ...mapGetters(['operationMenuTree']),
    pageTitle() {
      return "特别事件报告"
    },
    breadcrumb() {
      const pages = [{name:"业务管理",path:""}]
      pages.push({name:"报告/报表管理",path:""})
      pages.push({name:this.pageTitle,path:""})
      return pages
    },
  },
  watch:{
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepsid = val[val.length-1];
      }else {
        this.queryParams.userdepsid = '';
      }
    },
    queryInstallDate(val) {
      let {start, end} = getTimeRange(val);
      this.queryParams.starttime = start;
      this.queryParams.endtime = end;
    },
  },
  created() {
    let time = new Date();
    let monthNum = moment(time).format("YYYY-MM").slice(5);
    this.queryParams.starttime = moment(time).month(monthNum - 1).date(1).startOf("month").format("YYYYMMDD");
    this.queryParams.endtime = moment(time).month(monthNum - 1).date(1).endOf("month").format("YYYYMMDD");
    this.menu=getItemFromArrayByKey(this.operationMenuTree,"menuid",this.$route.params.menuid,"children")
    for(let i=0;i<this.menu.children.length;i++){
      this.btnList.push(this.menu.children[i].menuname)
    }
    this.getTableData()
    this.initDeptOptionsAll();
  },
  methods:{
    callback(key){
      this.resetQueryParams()
      this.getTableData(true)
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getSpecialEventListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    resetQueryParams() {
      this.userdepidCascaderSelected=[]
      this.queryInstallDate = [];
      this.queryParams.userdepsid=''
      this.$refs.queryForm.resetFields();
    },
    operationClick({key}){
      let arr = key.split('-');
      let type = arr[0];
      let special_event_id = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'special_event_id', special_event_id);
      if(type == 'delete') {
        this.deleteConfirm(special_event_id, record)
      }else{
        this.showModal(type,record)
      }
    },
    showModal(type,record){
      if(type=='detail') {
        this.modalDetailData = record
        this.modalShowType = type
        this.modalDetailData.btnList=this.btnList
        this.modalVisible = true
      }else if(type=='edit') {
        this.modalDetailData = record
        this.modalShowType = type
        this.modalVisible = true
      }
    },
    addRecord() {
      this.modalVisible = true
      this.modalShowType = 'add'
    },
    exportModal(){
      this.$confirm('你确认要按照所选筛选条件导出文件吗？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.exportSpecialEvent()
      }).catch(()=>{
      });
    },
    exportSpecialEvent(){
      let params = {
        ...this.queryParams,
      }
      this.showLoading();
      exportSpecialEventListExcel(params).then((res)=> {
        this.hideLoading();
        this.$message.success("导出成功")
      }).catch(()=>{
        this.hideLoading();
      })
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据?',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(record.special_event_id);
      }).catch(()=>{

      }) ;
    },
    delete(special_event_id) {
      this.showLoading();
      if(special_event_id) {
        let params = {
          special_event_id
        };
        deleteSpecialEvent(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
    deleteBatch() {
      if(this.batchrowids) {
        this.deleteBatchConfirm();
      }else {
        this.$message.warning('请先选择删除项');
      }
    },
    deleteBatchConfirm() {
      this.$confirm('确定要删除选中数据?',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(this.batchrowids);
      }).catch(()=>{

      }) ;
    },
    customRow(record, index){
      return {
        on: { // 事件
          click: () => {
            this.modalDetailData=record
            this.modalShowType="detail"
            this.modalDetailData.btnList=this.btnList
            this.modalDetailData.enable = true
            this.modalVisible=true
          },
        },
      }
    },
  },
}
</script>
<style scoped>

</style>